import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';

export default function Preferences() {

    return (
    <Box>
        <Switch name={"Setting_1"} defaultChecked/>
        <Switch name={"Setting_2"} />
        <Switch name={"Setting_3"} disabled defaultChecked/>
        <Switch name={"Setting_4"} disabled/>
    </Box>
    )
}
