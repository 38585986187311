import * as React from 'react';
import LandingPage from "./pages/landing_page/LandingPage";
import {Routes, Route} from 'react-router-dom';
import {useAuth0} from "@auth0/auth0-react";
import {AuthenticationGuard} from "./pages/auth/AuthenticationGuard";
import "./styles/GlobalStyle.css"
import {PageLoader} from "./components/PageLoader";
import Overview from "./pages/overview/Overview";
import Timesheets from "./pages/timesheets/Timesheets";
import AccountPageDrawer from "./components/AccountPageDrawer";
import Forecast from "./pages/forecast/Forecast";
import ProjectSpend from "./pages/project_spend/ProjectSpend";
import Preferences from "./pages/preferences/Preferences.";
import UploadInvoice from "./pages/upload_invoice/UploadInvoice";
import Budget from "./pages/budget/Budget";
import GenerateReports from "./pages/generate_reports/GenerateReports";

export default function Main() {

    const {isLoading} = useAuth0();

    if (isLoading) {
        return (
            <div className="page-layout">
                <PageLoader/>
            </div>
        );
    }

    return (
        <Routes>
            <Route path="/" element={<LandingPage/>}/>

            <Route
                path={process.env.REACT_APP_PAGES_OVERVIEW}
                element={<AuthenticationGuard component={() => <AccountPageDrawer app={Overview}/>}/>}
            />
            <Route
                path={process.env.REACT_APP_PAGES_PROJECT_SPEND}
                element={<AuthenticationGuard component={() => <AccountPageDrawer app={ProjectSpend}/>}/>}
            />
            <Route
                path={process.env.REACT_APP_PAGES_TIMESHEETS}
                element={<AuthenticationGuard component={() => <AccountPageDrawer app={Timesheets}/>}/>}
            />
            <Route
                path={process.env.REACT_APP_PAGES_BUDGET}
                element={<AuthenticationGuard component={() => <AccountPageDrawer app={Budget}/>}/>}
            />
            <Route
                path={process.env.REACT_APP_PAGES_FORECAST}
                element={<AuthenticationGuard component={() => <AccountPageDrawer app={Forecast}/>}/>}
            />
            <Route
                path={process.env.REACT_APP_PAGES_GENERATE_REPORT}
                element={<AuthenticationGuard component={() => <AccountPageDrawer app={GenerateReports}/>}/>}
            />
            <Route
                path={process.env.REACT_APP_PAGES_UPLOAD_INVOICE}
                element={<AuthenticationGuard component={() => <AccountPageDrawer app={UploadInvoice}/>}/>}
            />
            <Route
                path={process.env.REACT_APP_PAGES_PREFERENCES}
                element={<AuthenticationGuard component={() => <AccountPageDrawer app={Preferences}/>}/>}
            />
            {/*<Route path="/callback" element={<CallbackPage />} />*/}


        </Routes>
    )
}