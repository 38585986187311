import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import MonitorHeartOutlinedIcon from "@mui/icons-material/MonitorHeartOutlined";
import ListItemText from "@mui/material/ListItemText";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import ListAltIcon from "@mui/icons-material/ListAlt";
import SavingsOutlinedIcon from "@mui/icons-material/SavingsOutlined";
import SsidChartIcon from "@mui/icons-material/SsidChart";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
import FilePresentOutlinedIcon from "@mui/icons-material/FilePresentOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import {useNavigate} from "react-router-dom";
import {styled, useTheme} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import MuiAppBar from "@mui/material/AppBar";
import {useEffect, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import Box from "@mui/material/Box";

const drawerWidth = 240;
const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));
const Main = styled('main', {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));


export default function AccountPageDrawer({app}) {
    const theme = useTheme();
    const [open, setOpen] = useState(true);
    const {user, getAccessTokenSilently} = useAuth0();
    const [userName, setUserName] = useState('');
    const MainApp = app;
    const navigate = useNavigate();

    const API_GET_USERNAME_URL = process.env.REACT_APP_DOMAIN + process.env.REACT_APP_API_GET_USERNAME;

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        // Define the function that will fetch the user name.
        async function fetchUserName() {
            try {
                const token = await getAccessTokenSilently();
                console.log('Fetching user name with token:', token);  // Debugging line
                const response = await fetch(API_GET_USERNAME_URL, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({email: user.email}),
                });
                const data = await response.json();
                console.log('Fetched data:', data);  // Debugging line
                if (response.ok) {
                    setUserName(data.name);
                } else {
                    throw new Error(data.error || 'Error fetching name');
                }
            } catch (err) {
                console.error(err);  // Log the error to the console for debugging
                setUserName('Error fetching name');
            }
        }

        // Check if user.email exists before calling fetchUserName
        if (user?.email) {
            fetchUserName();
        } else {
            setUserName('No email provided');
        }
    }, [user?.email, getAccessTokenSilently]); // Dependencies for useEffect

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <AppBar position="fixed" open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{mr: 2, ...(open && {display: 'none'})}}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography variant="h6" noWrap component="div">
                        Welcome to FAE
                    </Typography>
                </Toolbar>
            </AppBar>
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                },
            }}
            variant="persistent"
            anchor="left"
            open={open}
        >
            <DrawerHeader>
                <IconButton onClick={handleDrawerClose}>
                    {theme.direction === 'ltr' ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
                </IconButton>
            </DrawerHeader>
            <Divider/>
            <List>
                <ListItem key={"Overview"} disablePadding onClick={() => {
                    console.log("Overview clicked")
                    navigate(process.env.REACT_APP_PAGES_OVERVIEW)
                }}>
                    <ListItemButton>
                        <ListItemIcon>
                            <MonitorHeartOutlinedIcon/>
                        </ListItemIcon>
                        <ListItemText primary={"Overview"}/>
                    </ListItemButton>
                </ListItem>
                <ListItem key={"Project Spend"} disablePadding onClick={() => {
                    console.log("Project Spend clicked")
                    navigate(process.env.REACT_APP_PAGES_PROJECT_SPEND)
                }}>
                    <ListItemButton>
                        <ListItemIcon>
                            <AttachMoneyOutlinedIcon/>
                        </ListItemIcon>
                        <ListItemText primary={"Project Spend"}/>
                    </ListItemButton>
                </ListItem>
                <ListItem key={"Timesheets"} disablePadding onClick={() => {
                    console.log("Timesheets clicked")
                    navigate(process.env.REACT_APP_PAGES_TIMESHEETS)
                }}>
                    <ListItemButton>
                        <ListItemIcon>
                            <ListAltIcon/>
                        </ListItemIcon>
                        <ListItemText primary={"Timesheets"}/>
                    </ListItemButton>
                </ListItem>
                <ListItem key={"Budget"} disablePadding onClick={() => {
                    console.log("Budget clicked")
                    navigate(process.env.REACT_APP_PAGES_BUDGET)
                }}>
                    <ListItemButton>
                        <ListItemIcon>
                            <SavingsOutlinedIcon/>
                        </ListItemIcon>
                        <ListItemText primary={"Budget"}/>
                    </ListItemButton>
                </ListItem>
                <ListItem key={"Forecast"} disablePadding onClick={() => {
                    console.log("Forecast clicked")
                    navigate(process.env.REACT_APP_PAGES_FORECAST)
                }}>
                    <ListItemButton>
                        <ListItemIcon>
                            <SsidChartIcon/>
                        </ListItemIcon>
                        <ListItemText primary={"Forecast"}/>
                    </ListItemButton>
                </ListItem>
                <ListItem key={"Generate Report"} disablePadding onClick={() => {
                    console.log("Generate Report clicked")
                    navigate(process.env.REACT_APP_PAGES_GENERATE_REPORT)
                }}>
                    <ListItemButton>
                        <ListItemIcon>
                            <SummarizeOutlinedIcon/>
                        </ListItemIcon>
                        <ListItemText primary={"Generate Report"}/>
                    </ListItemButton>
                </ListItem>
                <ListItem key={"Upload Invoice"} disablePadding onClick={() => {
                    console.log("Upload Invoice clicked")
                    navigate(process.env.REACT_APP_PAGES_UPLOAD_INVOICE)

                }}>
                    <ListItemButton>
                        <ListItemIcon>
                            <FilePresentOutlinedIcon/>
                        </ListItemIcon>
                        <ListItemText primary={"Upload Invoice"}/>
                    </ListItemButton>
                </ListItem>
            </List>
            <Divider/>
            <ListItem key={"Preferences"} disablePadding onClick={() => {
                console.log("Preferences clicked")
                navigate(process.env.REACT_APP_PAGES_PREFERENCES)
            }}>
                <ListItemButton>
                    <ListItemIcon>
                        <SettingsOutlinedIcon/>
                    </ListItemIcon>
                    <ListItemText primary={"Settings"}/>
                </ListItemButton>
            </ListItem>
        </Drawer>
            <Main open={open}>
                <DrawerHeader/>

                <MainApp/>
            </Main>
        </Box>

    )
}