import SignInButton from "./components/SignInButton";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./LandingPage.module.css";

const LandingPage = () => {

    const navigate = useNavigate();

    const handleSignIn = () => {
        // Add your sign-in logic here (e.g., API call)
        console.log("Sign in Pressed")
        // On successful sign-in, navigate to the dashboard
        // navigate('/signin');
    };

    return (
        <div className={styles.background_container}>
            <div className={styles.landing_page_nav_bar}>
                <div className={styles.something}>
                    <div className={styles.landing_page_company_text}>FAE</div>
                </div>
                <div className={styles.signin_button_container}>
                    <SignInButton className={styles.landing_page_button} onClick={handleSignIn} variant="text"> </SignInButton>
                </div>
            </div>
            <div className={styles.landing_page_hero_text_container}>
                <p className={styles.landing_page_hero_text}>Track Budgets</p>
                <p className={styles.landing_page_hero_text}>Automate Reports</p>
                <p className={styles.landing_page_hero_text}>Generate Forecasts</p>
                <p className={styles.landing_page_hero_text}>Manage Invoices</p>
                <p className={styles.landing_page_hero_text}>Audit Grants</p>
            </div>
            <div className={styles.landing_page_monkey_image_container}>
            <img src={"/assets/FairyHoldingWand.png"}
                     className={styles.landing_page_fairy_image}
                     alt={"Fairy Holding Wand"}
                />
            </div>

        </div>
    )

}


export default LandingPage