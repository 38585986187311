import {Auth0Provider} from "@auth0/auth0-react";
import {useNavigate} from "react-router-dom";

export const Auth0ProviderWithNavigate = ({children}) => {
    const navigate = useNavigate();

    const domain = process.env.REACT_APP_AUTH0_DOMAIN;
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
    const redirectUri = process.env.REACT_APP_DOMAIN + process.env.REACT_APP_PAGES_OVERVIEW;

    const onRedirectCallback = (appState) => {
        console.log(`Redirecting to ${redirectUri}`)

        navigate(appState?.returnTo || window.location.pathname);
    };

    if (!(domain && clientId && redirectUri)) {
        return null;
    }

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            authorizationParams={{
                redirect_uri: redirectUri,
            }}
            onRedirectCallback={onRedirectCallback}

        >
            {children}
        </Auth0Provider>
    );
};
