import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DateCalendar} from '@mui/x-date-pickers/DateCalendar';
import dayjs from 'dayjs';
import styled from 'styled-components'
import Badge from '@mui/material/Badge';
import {PickersDay} from "@mui/x-date-pickers/PickersDay";

const CalendarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`;

const YearlyCalendarContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
`;

const YearlyCalendarStyle = styled(DateCalendar)`
  flex: 1;
  max-width: 300px;
  .MuiPickersDay-today {
    background-color: #ff5722 !important; /* Orange */
    color: #fff !important; /* Custom text color */
  };
  .MuiPickersDay-root.Mui-selected {
    background-color: #ff33cc !important; /* Pink */
    color: #fff !important;
  };
  
  .MuiPickersDay-dayWithMargin.Mui-selected {
    background-color: #009999 !important; /* Cyan */
    color: #fff !important;
  };
  
  .Mui-selected:hover {
    background-color: #66ff66 !important; /* Green */
  };
  
  .MuiPickersDay-dayWithMargin.Mui-selected:hover {
    background-color: #FFFF00 !important; /* Yellow */
    color: #fff !important;
  };
  
  .MuiPickersDay-root:hover {
    background-color: #add8e6 !important; /* Light Blue for non-selected hovers */
  }
  
  .MuiPickersCalendarHeader-root .MuiIconButton-root {
    display: none; /* Hide the navigation buttons */
  }
`;


const Calendar = styled(DateCalendar)`
  flex: 1;
  max-width: 300px;
  .MuiPickersDay-today {
    background-color: #ff5722 !important; /* Orange */
    color: #fff !important; /* Custom text color */
  };
  .MuiPickersDay-root.Mui-selected {
    background-color: #ff33cc !important; /* Pink */
    color: #fff !important;
  };
  
  .MuiPickersDay-dayWithMargin.Mui-selected {
    background-color: #009999 !important; /* Cyan */
    color: #fff !important;
  };
  
  .Mui-selected:hover {
    background-color: #66ff66 !important; /* Green */
  };
  
  .MuiPickersDay-dayWithMargin.Mui-selected:hover {
    background-color: #FFFF00 !important; /* Yellow */
    color: #fff !important;
  };
  
  .MuiPickersDay-root:hover {
    background-color: #add8e6 !important; /* Light Blue for non-selected hovers */
  }
`;

//
// const Calendar = styled(DateCalendar)`
//   flex: 1;
//   max-width: 300px;
//   .MuiPickersDay-daySelected{
//     background-color: #ff5722 !important; /* Custom background color */
//     color: #fff !important; /* Custom text color */
//   };
//   .MuiPickersDay-dayWithMargin.Mui-selected {
//      background-color: #009999 /* Cyan */;
//      color: #fff;
//   };
//
//   .Mui-selected:hover {
//     background-color: #e64a19 !important; /* Custom hover color */
//   };
// `;


const PreviousCurrentNextMonthCalendar = () => {
    {
        const currentDate = dayjs();

        const previousMonth = currentDate.subtract(1, 'month');
        const nextMonth = currentDate.add(1, 'month');


        return (<CalendarContainer>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">

                    <Calendar referenceDate={previousMonth} autoFocus={false} disableHighlightToday={true}
                              views={['day']}/>
                    <Calendar defaultValue={currentDate} autoFocus={false} disableHighlightToday={false}
                              views={['day']}/>
                    <Calendar referenceDate={nextMonth} autoFocus={false} disableHighlightToday={true} views={['day']}/>
                </LocalizationProvider>
            </CalendarContainer>

        )
    }
};


const renderDay = (day, selectedDate, dayInCurrentMonth, dayComponent) => {
    const isSpecialDay = day.isSame(dayjs('2024-08-07'), 'day'); // Change to the specific day you want to badge

    if (isSpecialDay) {
        return (<Badge key={day.toString()} badgeContent="🎉">
                {dayComponent}
            </Badge>);
    }

    return dayComponent;
};

function ServerDay(props) {
    const {highlightedDays = [], day, outsideCurrentMonth, ...other} = props;

    const isSelected = !props.outsideCurrentMonth && highlightedDays.indexOf(props.day.date()) >= 0;

    return (<Badge
            key={props.day.toString()}
            overlap="circular"
            badgeContent={isSelected ? '💸' : undefined}
        >
            <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day}/>
        </Badge>);
}

const YearlyCalendar = () => {
    const currentYear = dayjs().year();

    // Generate the first date of each month for the current year
    const months = Array.from({length: 12}, (_, index) => dayjs(new Date(currentYear, index, 1)));

    const highlightedDays = [1, 2, 3]

    return (<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
            <YearlyCalendarContainer>
                {months.map((month, index) => {
                    if (month.month() === dayjs().month()) {
                        return (<YearlyCalendarStyle
                                key={index}
                                defaultValue={dayjs()}
                                autoFocus={true}
                                disableHighlightToday={false}
                                views={['day']}
                                slots={{
                                    day: ServerDay,
                                }}
                                slotProps={{
                                    day: {
                                        highlightedDays,
                                    },
                                }}/>)
                    } else {
                        return (<YearlyCalendarStyle
                                key={index}
                                referenceDate={month}
                                autoFocus={false}
                                disableHighlightToday={true}
                                views={['day']}
                                slots={{
                                    day: ServerDay,
                                }}
                                slotProps={{
                                    day: {
                                        highlightedDays,
                                    },
                                }}
                            />)
                    }
                })}
            </YearlyCalendarContainer>
        </LocalizationProvider>);
};


export {PreviousCurrentNextMonthCalendar, YearlyCalendar};


