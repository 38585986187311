import React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import {useAuth0} from "@auth0/auth0-react";

const BlackButton = styled(Button)({
    backgroundColor: '#000000',
    color: '#ffffff',
    '&:hover': {
        backgroundColor: '#333333'
    }
});

export default function SignInButton(props) {
  const { loginWithRedirect } = useAuth0();

    const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: process.env.REACT_APP_PAGES_OVERVIEW,
      },
    });
  };


    return (
        <BlackButton {...props} onClick={handleLogin}>
            Sign In
        </BlackButton>
    );
}